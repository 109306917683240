.login-title{
	font-size: 60px;
}

.login-disclaimer{
	margin-bottom: 50px;
}

.music-disclaimer{
	font-size: 70px;
	color: #ffb100;
}

.music-information{
	font-size: 70px;
	color: var(--bs-blue);
}