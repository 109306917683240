:root {
	--app-height: 100%;
}

.overload-contain {
    display: table;
    width: 100%;
}

.overload-contain .overload-contain-child {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.overload-contain .overload-contain-child .overload-element {
    max-width: 675px;
    width: 100%;
    display: inline-block;
    text-align: left;
    padding: 20px;
}

.overload-contain .overload-contain-child .overload-element-nopadding {
    width: 100%;
    display: inline-block;
}

.overload-on-top {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.spinner-background {
    background-color: #ffffffab;
	z-index: 100000;
}

.card-shadow {
	box-shadow: 0px 0px 10px #00000063;
}