.chat-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.chat-section {
    display: flex;
    flex-flow: column;
    height: 100vh;
	height: var(--app-height);
}

.chat-header {
    padding-top: 50px;
    border-bottom: 1px solid #dbdbdb;
    box-shadow: 0px 5px 10px #dbdbdb;
    font-weight: bold;
    font-size: 12px;
}

.chat-body{
    flex: 1;
    overflow: auto;
}

.chat-footer {
    background: #fff;
	z-index: 2;
}

.chat-body-section{
    border-right: 1px solid #dbdbdb;
    display: flex;
    flex-flow: row;
    height: 100%;
}

.chat-rooms{
    height: 100%;
    border-right: 1px solid #dbdbdb;
    box-shadow: 0px 0px 10px #dbdbdb;
	padding-top: 45px;
}

.chat-rooms-list{
	width: 250px;
}

.chat-mobile{
    border-right: 1px solid #dbdbdb;
}

.chat-rooms-mobile-action{
    height: 100%;
    box-shadow: 0px 0px 10px #dbdbdb;
	position: fixed;
	top: 0;
	left: 0;
	width: 30px;
	cursor: pointer;
	background-color: #fff;
}

.chat-rooms-mobile-action > div{
    display: flex;
    justify-content: center;
    align-items: center;   /* <---- NEW    */
    width: 100%;
    height: 100%;
}

.chat-rooms-mobile-open{
	width: 100%;
}

.chat-rooms-title{
	text-align: center;
	padding: 10px;
	border-bottom: 1px solid #dbdbdb;
	box-shadow: 0px 0px 10px #dbdbdb;
	font-weight: bold;
}

.chat-space{
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
	padding-top: 50px;
	padding-left: 50px;
}

.chat-space-section{
	display: flex;
    flex-flow: row;
    height: 100%;
}

.chat-space-list{
	width: 100%;
}

.chat-space-list > .row:last-child{
	padding-bottom: 20px;
}

.chat-space-room-list{
	position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
	z-index: 1;
}

.chat-online-user-item{
	cursor: pointer;
}

.chat-online-user-image{
	height: 30px;
	padding-right: 10px;
}