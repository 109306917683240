.wrapper-banner{
	height: 200px;
	background-size: cover;
	background-position: center center;
}

.wrapper-banner-blur{
	height: 200px;
	width: 100%;
	background-color: #00000057;
}

.wrapper-banner-container {
	height: 200px;
	width: 100%;
}

.wrapper-title{
	font-size: 30px;
	color: #fff;
}

.wrapper-disclaimer{
	font-size: 15px;
	color: #fff;
}

.wrapper-menu{
	margin-bottom: 60px;
}

.wrapper-menu-element {
	border-bottom: 1px solid #ccc;
	width: 100%;
	display: inline-block;
	text-align: left;
	padding: 10px;
	box-shadow: 0px 0px 10px #dbdbdb;
}

.wrapper-menu-title{
	text-transform: uppercase;
	font-weight: bold;
	font-size: 13px;
	vertical-align: middle;
    margin-top: 3px;
}

.wrapper-menu-contain{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	background-color: rgb(0, 94, 202);
	color: #fff;
}

.wrapper-menu-back{
	cursor: pointer;
}

.wrapper-menu-right{
	position: fixed;
	right: 20px;
	top: 20px;
	font-size: 30px;
	color: #fff;
	cursor: pointer;
}