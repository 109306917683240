.chat-message-box{
	display: inline-block;
	padding: 10px;
	border-radius: 15px;
}

.chat-message-my{
	text-align: right;
}

.chat-message-my-box{
	background-color: #0e6efd;
	color: #fff
}

.chat-message-other-box{
	background-color: #f1f1f1;
}

.chat-message{
	display: inline-block;
}

.chat-message-text{
	text-align: left;
}

img.chat-message{
	vertical-align: top;
}

.chat-message-image{
	width: 80px;
}

.chat-message-image img{
	height: 50px;
}

.chat-message-time{
	font-size: 11px;
    text-align: right;
    margin-top: 7px;
    font-style: italic;
}