.home-app-container{
	border: 1px solid #dbdbdb;
	padding: 20px;
	text-align: center;
	border-radius: 15px;
	box-shadow: 0 0 10px #dbdbdb;
	cursor: pointer;
}

.home-app-logo i{
	font-size: 60px;
	color: #0d6efd;
}

.home-app-title{
	font-weight: bold;
}