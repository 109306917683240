.card-img {
  object-fit: cover;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 25rem;
  display: block;
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  background-size: cover;
}
