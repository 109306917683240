.home-margin-top{
	margin-top: -45px;
}

.home-disclaimer{
	font-size: 70px;
	color: #ffb100;
}

.home-profile-nickname{
	font-weight: bold;
	padding-top: 10px;
}